<template>
  <div class="ai-search-form">
    <autosize-textarea
      :initial-text="text"
      class="filter"
      :placeholder="textareaPlaceholder"
      @keydown.enter.prevent="submit"
      @input="onInput"
    >
      <div class="submit-container">
        <mcr-button class="small square" :loading="aiSearchPromptLoadingState" :disabled="!text" @click="submit"
          ><send-icon :size="20"></send-icon
        ></mcr-button>
      </div>
    </autosize-textarea>
    <template v-if="!inputOnly">
      <div v-if="showError" class="help-text error">
        {{ errorMessage }}
      </div>
      <mcr-wiki-glossary-link
        v-else
        naturalId="ai-search"
        text="How to write effective search queries"
        :is-help-text="true"
        class="text-sm tip"
      ></mcr-wiki-glossary-link>
    </template>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import AutosizeTextarea from '@common/elements/inputs/AutosizeTextarea';
import {getRedirectRouteFromAiSearch} from '@common/pages/searches/helpers/utils';
import SendIcon from 'vue-material-design-icons/Send';
import {mapGetters} from 'vuex';

const mcrWikiGlossaryLink = () => import('@common/elements/glossary/mcrWikiGlossaryLink');

export default {
  props: {
    inputOnly: {type: Boolean, default: false},
  },
  data() {
    return {
      text: this.$store.getters.aiSearchQueryState,
      showError: false,
      errorMessage:
        "We couldn’t process this request. Try specifying details like a name, location, or time period. For example: 'I’m looking for census records of Sam Wang in California from the 1920s'.",
      emptyQueryRedirectRoutes: ['chinese_places_search', 'world_places_search', 'surname_search'],
    };
  },
  computed: {
    ...mapGetters(['aiSearchPromptLoadingState']),
    textareaPlaceholder() {
      const examples = [
        // 'e.g., My great-grandfather Shu-Yung Liu was born in Toisan, do you have his zupu?',
        'e.g., 王子槐 born in 观澜 and migrated to Jamaica in the 1920s',
        'e.g., My grandfather Wong Swee Ling arrived in New York around 1950 and died in 1999',
        'e.g., I’m looking for my grandfather 李伟 who lived in Guangdong in the 1950s',
      ];
      return examples[Math.floor(Math.random() * examples.length)];
    },
  },
  methods: {
    onInput(event) {
      this.text = event.target.value;
    },
    submit() {
      if (!this.text || this.aiSearchPromptLoadingState) {
        return;
      }

      this.$store
        .dispatch('askAiBySearchPromptAction', {search_prompt: this.text})
        .then(response => {
          this.processResponse(response);
        })
        .catch(() => {
          this.handleSearchError();
        });
    },
    processResponse(response) {
      this.showError = false;
      if (!response.search_name) {
        return this.handleSearchError();
      }
      const route = getRedirectRouteFromAiSearch(response);
      if (!route) {
        return this.handleSearchError();
      }
      const newState = this.emptyQueryRedirectRoutes.includes(response.search_name) ? '' : this.text;
      this.$store.commit('setAiSearchQueryState', newState);
      this.$store.commit('setAiUsedRecentlyState', true);
      this.$emit('submit');
      this.redirectToRoute(route);
    },
    redirectToRoute(route) {
      this.$router.push(route);
    },
    handleSearchError() {
      this.showError = true;
      if (this.inputOnly) {
        this.$toasted.error(this.errorMessage);
      }
    },
  },
  name: 'AiSearchForm',
  components: {McrButton, AutosizeTextarea, SendIcon, mcrWikiGlossaryLink},
};
</script>

<style scoped lang="scss">
.ai-search-form {
  position: relative;
  .filter {
    display: flex;
    textarea {
      flex-grow: 1;
    }
  }
  .help-text {
    margin-top: 8px;

    &.tip {
      color: $primary-600;
    }

    &.error {
      font-style: italic;
    }
  }
  .autosize-textarea {
    position: relative;
  }
  .submit-container {
    position: absolute;
    bottom: 12px;
    right: 12px;
  }
}
</style>
